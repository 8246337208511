@import "@spreetail/meta-tokens";
@import "./bidding-tokens.scss";

.bidding-external {
    .logo {
      height: 40px;
  }

  .meta-content-layout {
    width: 100vw;
  }

  .meta-action-bar {
    align-items: center;
    background-color: $t-color-neutral-07;
    color: $t-color-neutral-01;
    justify-content: flex-start;

    span {
      border-left: 1px solid $t-color-neutral-01;
      padding: 0 $t-space-unit-base;
      margin: $t-space-unit-base;
    }
  }

  .auction-layout {
    display: grid;
    grid-gap: $t-space-unit-larger;
    grid-template-areas:
      "overview"
      "bids-broker"
      "routing";
  }

  .overview {
    grid-area: overview;
  }

  .routing {
    grid-area: routing;
  }

  .bids-broker {
    grid-area: bids-broker;
    margin-top: 0px;

    .meta-btn {
      margin-top: -4px;
      margin-bottom: -4px;
    }

    .meta-card__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      color: $t-color-neutral-05;
    }
  }

}

@media only screen and (min-width: $breakpoint-4) {
  .bidding-external {
    .auction-layout {
      grid-template-areas:
      "overview overview overview bids-broker"
      "routing routing routing routing";
    }
  }
}

@import "../components/modal-bid-new-edit/modal-bid-new-edit.styles.scss";
@import "../components/input-field/input-field.style.scss";