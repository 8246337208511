#bids {
  .header {
    display: flex;

    h3 {
      margin-right: $t-space-unit-small;
    }
  }

  .meta-table {
    .rt-th, .rt-td {
      padding: $t-space-unit-small $t-space-unit-base;
    }
  }

  .status-tag.winning {
    .status__dot {
      background-color: $t-color-success-02;
    }
  }
}