.meta-sub-nav {
  height: $t-space-xl * 2 + 1px;
  border: 0;
  border-bottom: $border;

  .meta-card__header {
    padding: $t-space-unit-base $t-space-xl * 2 0;
  }

  .meta-card__content {
    display: none;
  }
}