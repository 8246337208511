.overview-card {
  display: flex;
  width: 100%;

  h5 {
    margin: 0;
  }

  &:not(:last-child) {
    border-bottom: $border;
  }

  &__key,
  &__value {
    align-items: center;
    display: flex;
    width: 50%;
  }

  &__key {
    background-color: $t-color-neutral-03;
    flex: 1 0 auto;
    padding: $t-space-unit-smaller $t-space-unit-base;
    border-right: $border;
  }

  &__value {
    padding: $t-space-unit-base;
  }
}

@media only screen and (min-width: $breakpoint-4) {
  .overview-card {
    flex-direction: column;
    border-bottom: none;


    &:not(:last-child) {
      border-bottom: none;

      &.is-editable {
        border-right: $border;
      }
    }

    &__key,
    &__value {
      border: none;
      width: 100%;
    }

    &__key {
      border-bottom: $border;
    }

    &__value {
      padding: $t-space-unit-large $t-space-unit-base;
    }
  }
}