.routing-details-pickup-contacts {
  max-width: 1200px;

  .meta-table {

    .rt-th:not(:last-child),
    .rt-td:not(:last-child) {
      border-right: 1px solid $t-color-border-default;
    }
  }
}
