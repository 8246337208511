@mixin banner($dark, $background) {

  background-color: rgba($background, 0.15);
  border-color: $dark;

  .meta-icon {
    fill: $dark;
  }
}

.banner {
  align-items: center;
  border: $border;
  background-color: $t-color-neutral-03;
  border-color: $t-color-neutral-05;
  border-radius: $t-space-border-radius;
  display: flex;
  margin-bottom: $t-space-unit-base;
  padding: $t-space-unit-base;

  &.info {
    @include banner($t-color-primary-04, $t-color-primary-02);
  }

  &.critical {
    @include banner( $t-color-critical-03, $t-color-critical-02);

  }

  &.warning {
    @include banner($t-color-warning-03, $t-color-warning-02);
  }

  &.success {
    @include banner($t-color-success-03, $t-color-success-02);
  }

  .meta-icon {
    margin-right: $t-space-unit-small;
  }
}