.routing-details-pallets {

  &.meta-expandable-panel {

    &.is-expanded {
      border-color: $t-color-border-default;

      .meta-expandable-panel__header {
        border-bottom: $border;

        .meta-expandable-panel__header__details__title {
          color: $t-color-neutral-06;
        }

        .panel-collapse-icon {
          fill: rgba($t-color-neutral-07, 0.85);
        }
      }
    }

    &:hover {

      .meta-expandable-panel__header {
        .panel-collapse-icon {
          fill: rgba($t-color-neutral-07, 0.85);
        }
      }
    }
  }

  .meta-expandable-panel__body {
    padding: 0;
  }

  .meta-expandable-panel__header {
    height: auto;
    padding: 0 $t-space-unit-large;

    &__details__title {
      line-height: $t-type-size-line-height-heading-3;
      font-size: $t-type-size-heading-3;
      margin: 20px 0 20px 8px;
    }
  }
}