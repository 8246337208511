.status-tag {
  display: flex;

  .status__dot {
    background-color: $t-color-neutral-02;
    border-radius: 50%;
    display: block;
    margin-right: $t-space-unit-smaller;
    width: $t-space-unit-base;
    height: $t-space-unit-base;
  }

  &.active {
    .status__dot {
      background-color: $t-color-success-02;
    }
  }

  &.complete {

    .meta-icon {
      margin-right: $t-space-unit-smaller;
      fill: $t-color-success-02;
    }
  }
}