@import "@spreetail/meta-tokens";
@import "./bidding-tokens.scss";

.meta-content-floor {
  background-color: $t-color-neutral-02;
  padding: $t-space-unit-large $t-space-xl * 2;
}

.meta-content-layout {
  &.with-sub-nav {

    .meta-content-floor {
      height: calc(100vh - (80px + 50px));
    }
  }
}

.meta-card {
  + .meta-card {
    margin-top: $t-space-xxxl;
  }

  &.no-padding {

    .meta-card__content {
      padding: 0;
    }

    .meta-table {
      border: 0;
    }
  }
}

.meta-table {
  .rt-thead {
    border-bottom: $border;

    .rt-th {
      background-color: $t-color-neutral-03;
      color: $t-color-neutral-07;
      font-size: $t-type-size-heading-5;
    }
  }

  .rt-resizable-header-content {
    font-family:Roboto, -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
    font-size: $t-type-size-heading-5;
    font-weight: $t-type-weight-bold;
    line-height: $t-type-size-line-height-heading-5;
    padding-top: $t-space-unit-smaller $t-space-unit-base;
  }

  // .rt-tbody {
    // .rt-th:not(:last-child),
    // .rt-td:not(:last-child) {
    //   display: block;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   white-space: nowrap;
    // }
  // }

  .align-right {
    justify-content: flex-end;
  }
}

// column when narrow screen, row at 900px
.flex-row {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $breakpoint-4) {
  .flex-row {
    flex-direction: row;
  }
}

.meta-btn:disabled,
.meta-btn.disabled,
.meta-btn[disabled] {

  &.outline-primary {
    border-color: $t-color-neutral-04;
  }
}

.meta-modal {

  &__content {
    overflow: auto;
    max-height: calc(100vh - 280px);
  }

  &__footer {

    .meta-btn {

      + .meta-btn {
        margin-left: $t-space-unit-base;
      }
    }
  }
}

*::-webkit-scrollbar {
  height: $t-space-unit-smaller;
  width: $t-space-unit-smaller;

  &:hover {
    width: $t-space-unit-small;
  }

  &-track {
    background-color: $t-color-neutral-02;
  }

  &-thumb {
    background-color: rgba($t-color-neutral-05, .8);
    border: 1px solid $t-color-neutral-02;
    border-radius: $t-space-border-radius;

    &:hover {
      background-color: rgba($t-color-neutral-05, .6);
      cursor: pointer;
    }
  }
}


@import "../components/action-bar-with-sub-nav/styles.scss";
@import "../components/banner/styles.scss";
@import "../components/bids/styles.scss";
@import "../components/key-value-card/styles.scss";
@import "../components/status-tag/styles.scss";
@import "../components/step-header/step-header.styles.scss";
@import "../components/routing-details/styles.scss";
@import "../components/routing-details-load/styles.scss";
@import "../components/routing-details-pickup-contacts/styles.scss";
